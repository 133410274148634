import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The IPv4Range scalar type is an IPv4 range, represented as UTF-8 character sequences.
Accepted formats are:`}</p>
    <ul>
      <li parentName="ul">{`IPv4 address without netmask (meaning netmask is `}<inlineCode parentName="li">{`/32`}</inlineCode>{`). i.e: `}<inlineCode parentName="li">{`127.0.0.1`}</inlineCode>{`, `}<inlineCode parentName="li">{`192.168.0.1`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`IPv4 address with netmask. i.e.: `}<inlineCode parentName="li">{`127.0.0.1/32`}</inlineCode>{`, `}<inlineCode parentName="li">{`192.168.0.0/24`}</inlineCode>{`, `}<inlineCode parentName="li">{`172.16.0.0/255.255.255.0`}</inlineCode>{`.`}</li>
    </ul>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">scalar</GQLKeyword> IPv4Range</GQLLine>
    </GQLCodeSnippet>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      